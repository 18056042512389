"use client";

// @subframe/sync-disable

/*
 * Documentation:
 * payment modal header — https://app.subframe.com/feb895689ba0/library?component=payment+modal+header_fe4ad82d-debc-4206-a3f5-8f739adc23d4
 * ASButton — https://app.subframe.com/feb895689ba0/library?component=ASButton_71931235-bb08-41fa-ad00-ec9684e17f79
 */

import React from "react";
import * as SubframeCore from "@subframe/core";
import { AsButton } from "./AsButton";
import { AsIconButton } from "./AsIconButton";

interface PaymentModalHeaderRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  size?: "default" | "small";
  className?: string;
  buttonProps?: React.ComponentProps<typeof AsButton>;
  backIsX?: boolean;
}

const PaymentModalHeaderRoot = React.forwardRef<
  HTMLElement,
  PaymentModalHeaderRootProps
>(function PaymentModalHeaderRoot(
  {
    text,
    size = "default",
    className,
    buttonProps,
    backIsX,
    ...otherProps
  }: PaymentModalHeaderRootProps,
  ref
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        "group/fe4ad82d flex w-full items-center gap-4",
        className
      )}
      ref={ref as any}
      {...otherProps}
    >
      {text ? (
        <span
          className={SubframeCore.twClassNames(
            "grow shrink-0 basis-0 text-[18px] font-[500] text-neutral-900",
            { "text-body-bold font-body-bold": size === "small" }
          )}
        >
          {text}
        </span>
      ) : null}
      {backIsX ? (
        <AsIconButton
          leadingIcon="FeatherX"
          trailingIcon={null}
          text="Label"
          size="xs"
          variant="ghost"
          disabled={false}
          disabledSecondary={false}
          onClick={(event) => buttonProps?.onClick?.(event as any)}
        />
      ) : (
        <AsButton
          variant="ghost"
          leadingIcon={null}
          trailingIcon={null}
          text="Back"
          size="xs"
          loader={false}
          disabled={false}
          disabledSecondary={false}
          {...buttonProps}
        />
      )}
    </div>
  );
});

export const PaymentModalHeader = PaymentModalHeaderRoot;
