import * as React from "react";
import { PaymentModalHeader } from "app/subframe/components/PaymentModalHeader";
import { DualButton } from "app/subframe/components/DualButton";
import { PoweredBy } from "app/subframe/components/PoweredBy";
import { DualPricingPaymentTypes } from "app/book/useBook";
import { round } from "lodash";

export interface DualPricingModalProps {
  setShowDualPricingModal: (open: boolean) => void;
  onPayBy: (method: DualPricingPaymentTypes) => void;
  cart: any;
}

export const DualPricingModal: React.FC<DualPricingModalProps> = ({
  setShowDualPricingModal,
  onPayBy,
  cart,
}) => {
  const cardSubtotal = round(cart.subtotalCents / 100, 2);
  const cashSubtotal = cardSubtotal * 0.96;
  return (
    <div className="flex w-full grow bg-white h-full shrink-0 basis-0 flex-col items-center justify-end gap-2 bg-[#171718cc] px-1 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:items-center mobile:justify-end mobile:px-0 mobile:py-0">
      <div className="flex w-full flex-col items-center gap-1">
        <PaymentModalHeader
          text={`${cart.shop.account.name} offers cash discount`}
          buttonProps={{
            onClick: () => setShowDualPricingModal(false),
          }}
          backIsX
        />
        <span className="w-full text-body font-body text-neutral-700">
          Choose a payment method
        </span>
      </div>
      <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-50" />
      <div className="flex w-full flex-col items-center gap-10">
        <DualButton
          icon="FeatherCreditCard"
          payType="Pay by Card"
          price={`$ ${cardSubtotal.toFixed(2)}`}
          text3=""
          onClick={() => onPayBy(DualPricingPaymentTypes.CARD)}
        />
        <DualButton
          icon="FeatherBanknote"
          payType="Pay by Cash"
          price={`$ ${cashSubtotal.toFixed(2)}`}
          text3="(Cash Discount)"
          variant="cash"
          onClick={() => onPayBy(DualPricingPaymentTypes.CASH)}
        />
      </div>
      <div className="absolute bottom-4 flex w-full grow shrink-0 basis-0 flex-col items-center justify-end gap-4">
        <PoweredBy text="Powered by" />
      </div>
    </div>
  );
};
