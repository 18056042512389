import { Icon } from "@subframe/core";
import { PaymentMethodTypes } from "app/order/useOrder";
import { AsButton, Header, IconButton } from "app/subframe";
import * as React from "react";

export interface PaymentFailedProps {
  account: any;
  shop: any;
  setHasCreditCardInfoFailed: (hasFailed: boolean) => void;
  setIsPreAuthModalOpen: (isOpen: boolean) => void;
  onPayWithCash: () => void;
  paymentMethod: any;
}

export const PaymentFailed: React.FC<PaymentFailedProps> = ({
  account,
  shop,
  onPayWithCash,
  setIsPreAuthModalOpen,
  setHasCreditCardInfoFailed,
  paymentMethod,
}) => {
  return (
    <div className="flex h-[95dvh] grow my-4 py-4 shrink-0 basis-0 flex-col items-center gap-4 rounded-2xl bg-millet-50 overflow-auto shadow-md mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0">
      <Header
        className="h-auto w-112 flex-none mobile:h-auto mobile:w-full mobile:flex-none"
        image={account.logo}
        text={account.name}
      />
      <div className="flex w-112 grow shrink-0 basis-0 flex-col items-center gap-4 px-2 mobile:w-full mobile:grow mobile:shrink-0 mobile:basis-0 mobile:flex-col mobile:gap-6 mobile:px-2 mobile:py-0">
        <div className="flex w-full items-center">
          <AsButton
            variant="ghost"
            leadingIcon={"FeatherChevronLeft"}
            trailingIcon={null}
            text="Back to order confirmation"
            size="sm"
            loader={false}
            onClick={() => setHasCreditCardInfoFailed(false)}
          />
        </div>
        <div className="flex w-full grow shrink-0 basis-0 flex-col items-center gap-4">
          <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
            <div className="flex w-full items-center gap-2">
              <Icon
                className="text-body font-body text-error-500"
                name="FeatherAlertTriangle"
              />
              <span className="text-[14px] font-[600] leading-[20px] text-neutral-900">
                Payment failed, try again or use a different payment method.
              </span>
            </div>
            <AsButton
              className="h-14 w-full flex-none"
              variant="secondary"
              leadingIcon="FeatherCreditCard"
              trailingIcon={null}
              text="Credit Card"
              size="lg"
              onClick={() => {
                paymentMethod === PaymentMethodTypes.CREDIT_CARD
                  ? setIsPreAuthModalOpen(false)
                  : setIsPreAuthModalOpen(true);
                setHasCreditCardInfoFailed(false);
              }}
            />
            {account.enableCashPaymentForBooking && (
              <>
                <AsButton
                  className="h-14 w-full flex-none"
                  variant="secondary"
                  leadingIcon="FeatherBanknote"
                  trailingIcon={null}
                  text="Pay with cash"
                  size="lg"
                  onClick={() => onPayWithCash()}
                />
              </>
            )}
            <span className="text-[14px] font-[600] leading-[20px] text-neutral-900">
              Contact {account.name}
            </span>
            {shop.phone && (
              <AsButton
                className="h-14 w-full flex-none"
                variant="secondary"
                leadingIcon="FeatherPhone"
                trailingIcon={null}
                text={shop.phone}
                size="lg"
                onClick={() => {
                  window.location.href = `tel:${shop.phone}`;
                }}
              />
            )}
            {shop.email && (
              <AsButton
                className="h-14 w-full flex-none"
                variant="secondary"
                leadingIcon="FeatherMail"
                trailingIcon={null}
                text={shop.email}
                size="lg"
                onClick={() => {
                  window.location.href = `mailto:${shop.email}`;
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
